<template>
  <span class="icon" @click="$emit('click')">
    <i
      v-if="pack !== 'maglr'"
      :class="[
        pack,
        'fa-' + icon,
        { 'fa-spin': spin, 'fa-fw ': fixedWidth },
        'fa-' + getFontawesomeSize
      ]"
    />
    <span v-else class="maglr">
      <span v-if="type === 'svg'" v-html="maglrIconContent" />
      <img v-if="type === 'img'" :src="maglrIconContent" alt="maglr icon">
    </span>
  </span>
</template>

<script>
/**
 * To use this Icon package, you MUST include the font awesome 5 CDN link. <br>
 * `<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css">`
 */

export default {
  name: 'MIcon',
  status: 'ready',
  release: '1.0.0',
  props: {
    /**
     * Icon name, valid font awesome icon. Check the `pack` family if your icon doesn't work.
     */
    icon: {
      type: String,
      default: ''
    },
    /**
     * Icon family. `fas`, `far`, fab`, or `maglr`
     */
    pack: {
      type: String,
      default: 'fas'
    },

    type: {
      type: String,
      default: 'svg',
      validator: (val) => {
        return ['svg', 'img'].includes(val);
      }
    },
    /**
     * Icon size, `is-small`, `is-medium`, `is-large`
     */
    size: {
      type: String,
      default: 'is-medium'
    },
    /**
     * If the icon should spin (animation)
     */
    spin: {
      type: Boolean,
      default: false
    },
    /**
     * Set the icon width to fixed (font awesome only)
     */
    fixedWidth: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  computed: {
    getFontawesomeSize () {
      switch (this.size) {
        case 'is-small':
          return 'xs';
        case 'is-medium':
          return '1x';
        case 'is-large':
          return '2x';
        default:
          return '1x';
      }
    },
    maglrIconContent () {
      switch (this.icon) {
        case 'close':
          return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 26"><path fill="none" stroke="#415D6E" stroke-linecap="square" stroke-width="4" d="M23.4 22.7 3.6 3.3m0 19.4L23.4 3.3"/></svg>';
        default:
          return null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import '/submodules/vue-framework/src/scss/Colors.scss';

span.icon {
  &.left {
    margin-right: 10px;
  }
  &.right {
    margin-left: 10px;
  }
  span.maglr {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<template>
  <div v-if="data.state.maglrLogin || data.state.providers.length === 0" class="maglr-login">
    <form @submit.prevent>
      <MField
        label-variant="is-dark"
        :validator="emailValidator"
        :label-left="$t('e-mailadres')"
        label-alignment="is-top"
        label-position="is-top-bottom"
      >
        <MInput
          v-model="state.email"
          type="email"
          name="email"
          :placeholder="$t('e-mailadres')"
          @blur="v$.email.$touch"
          @keyup.enter="login"
        />
      </MField>
      <MField
        label-variant="is-dark"
        :validator="passwordValidator"
        :label-left="$t('wachtwoord')"
        label-alignment="is-top"
        label-position="is-top-bottom"
      >
        <MInput
          v-model="state.password"
          name="password"
          autocomplete="current-password"
          type="password"
          :placeholder="$t('wachtwoord')"
          @blur="v$.password.$touch"
          @keyup.enter="login"
        />
      </MField>
      <vue-recaptcha v-if="state.showcaptcha" ref="recaptcha" sitekey="6Le6zCATAAAAACuTllPLLV_luzg3BUeuXTggRVes" @verify="recaptchaVerify" @error="recaptchaError" />
    </form>

    <div class="form-controls">
      <MButton :loading="state.loading" size="is-large" variant="is-accent-2" @click="login">
        {{ $t('login') }}
      </MButton>
      <div class="links">
        <MLink is="router-link" to="/login/requestpassword" variant="is-darker" target="_self" icon-right="long-arrow-right">
          {{ $t('wachtwoord vergeten') }}
        </MLink>
      </div>
    </div>
  </div>
  <div v-if="data.state.providers.length > 0 && data.state.maglrLogin" class="text-separator">
    {{ $t('of') }}
  </div>

  <div v-if="data.state.providers.length > 0" class="thirdparty-login">
    <div v-if="!state.ssoVisible" class="oauth-providers sso-visible">
      <MLink
        icon-left="key" target="_self" to="javascript:void(0)" variant="is-darker"
        @click="ssoVisibleClick"
      >
        {{ $t('sso_login_label') }}
      </MLink>
    </div>
    <div v-else class="oauth-providers">
      <MButton
        v-for="oauthProvider in data.state.providers"
        :key="oauthProvider.name"
        :icon-pack="oauthProvider.iconPack"
        :icon-left="oauthProvider.icon"
        class="oauth-button"
        variant="is-outlined is-light"
        size="is-large"
        @click="() => openUrl(oauthProvider.destination)"
      >
        {{ $t('login met') }} {{ oauthProvider.name }}
      </MButton>
    </div>
  </div>
</template>

<script>

import { computed, reactive, ref } from 'vue';
import { useApi } from '../services/Api';
import { useData } from '../services/Data';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { email, required, minLength } from '@vuelidate/validators';
import { VueRecaptcha } from 'vue-recaptcha';
import MField from '../../../submodules/vue-framework/src/components/Container/Field.vue';
import MInput from '../../../submodules/vue-framework/src/components/Form/Input.vue';
import MButton from '../../../submodules/vue-framework/src/components/Element/Button.vue';
import MLink from '../../../submodules/vue-framework/src/components/Element/Link.vue';

export default {
  name: 'LoginContainer',
  components: { MLink, MButton, MInput, MField, VueRecaptcha },
  setup () {
    const data = useData();
    const api = useApi();
    const router = useRouter();
    const i18n = useI18n();
    const state = reactive({
      email: '',
      password: '',
      errors: { email: '', password: '' },
      loading: false,
      logincount: 0,
      showcaptcha: false,
      recaptchaVerified: false,
      ssoVisible: false
    });
    state.ssoVisible = (localStorage.ssoVisible === 'true') ?? false;

    const recaptcha = ref(null);
    state.email = data.state.email ?? '';
    const rules = {
      email: { required, email }, // Matches state.email
      password: { required, minLength: minLength(6) } // Matches state.password
    };
    const v$ = useVuelidate(rules, state);

    const emailValidator = computed(() => {
      if (state.errors.email) {
        return {
          message: state.errors.email,
          state: 'is-danger',
          validation: false
        };
      }

      return {
        message: '',
        state: 'is-default',
        validation: true
      };
    });

    const passwordValidator = computed(() => {
      if (state.errors.password) {
        return {
          message: state.errors.password,
          state: 'is-danger',
          validation: false
        };
      }

      return {
        message: '',
        state: 'is-default',
        validation: true
      };
    });

    function openUrl (url) {
      localStorage.ssoVisible = true;
      window.location.href = url;
    }

    function recaptchaVerify (value) {
      state.errors.password = '';
      state.recaptchaVerified = value;
    }

    function recaptchaError () {
      state.recaptchaVerified = false;
    }
    function ssoVisibleClick () {
      localStorage.ssoVisible = true;
      state.ssoVisible = true;
    }

    async function login () {
      state.logincount++;
      if (recaptcha.value != null && state.showcaptcha && state.recaptchaVerified === false) {
        state.errors.password = 'Please tick recaptcha.';
        return;
      }
      state.loading = true;
      data.state.password = state.password;
      data.state.email = state.email;

      api.login({ email: state.email, password: state.password, token: state.recaptchaVerified })
        .then(r => {
          localStorage.ssoVisible = false;
          data.state.login_success = true;
          data.state.email = r.data.user?.email ?? data.state.email;
          data.state.user = r.data.user;
          data.state.singleSignOnProvider = r.data.single_sign_ex_provider;
          data.state.hasSingleSignOn = r.data.has_single_sign_on;
          data.state.needSingleSignOn = r.data.single_sign_on;
          if (typeof r.data.lang !== 'undefined') {
            window.i18n.global.locale = r.data.lang;
          }
          if (r.data.redirect != null && r.data.redirect !== '') {
            data.state.dashUri = r.data.redirect;
          }
          if (r.data.two_factor_activate_skip != null && r.data.two_factor_activate_skip !== '') {
            data.state.twoFactorSkipAllowed = r.data.two_factor_activate_skip;
            data.state.twoFactorSkipAllowedCount = r.data.two_factor_activate_skip_count;
          }
          if (r.data.single_sign_on_skip != null && r.data.single_sign_on_skip !== '') {
            data.state.singleSignOnSkipAllowed = r.data.single_sign_on_skip;
            data.state.singleSignOnSkipAllowedCount = r.data.single_sign_on_skip_count;
          }
          data.state.twoFactor = r.data.two_factor;
          if (typeof r.data.single_sign_on_providers !== 'undefined') {
            data.state.providers = r.data.single_sign_on_providers;
          }
          if (r.data.single_sign_on) {
            router.push('/login/sso/activate');
            return;
          }
          if (r.data.two_factor === true) {
            router.push('/login/twofactor/validate');
            return;
          }
          if (data.state.redirectAfterLogin !== false) {
            router.push(data.state.redirectAfterLogin);
            return;
          }
          if (data.state.firstpage !== false) {
            router.push(data.state.firstpage);
            return;
          }
          if (r.data.two_factor_activate === true) {
            router.push('/login/twofactor');
            return;
          }
          router.push('/login/redirect');
          window.location.href = '/login/redirect';
        })
        .catch(error => {
          state.errors.password = i18n.t('error invalid login');
          if (typeof error.response?.data?.errors?.email !== 'undefined' &&
            error.response?.data?.errors?.email[0] !== '' &&
            error.response?.data?.errors?.email[0] !== 'These credentials do not match our records.' &&
            typeof error.response?.data?.errors?.email[0] === 'string') {
            if (typeof error.response?.data?.errors?.sso_provider !== 'undefined' &&
              error.response?.data?.errors?.sso_provider[0] !== '' &&
              typeof error.response?.data?.errors?.sso_provider[0] === 'string') {
              state.errors.password = (error.response?.data?.errors?.email[0]).replace('[[provider]]', error.response?.data?.errors?.sso_provider[0]);
            } else {
              state.errors.password = (error.response?.data?.errors?.email[0]);
            }
          } else if (error.response?.data?.message != null &&
            error.response.data?.message !== 'The given data was invalid.') {
            state.errors.password = error.response.data.message;
          }
          if (recaptcha.value != null && state.showcaptcha && state.recaptchaVerified !== false) {
            recaptcha.value.reset();
            state.recaptchaVerified = false;
          }
        })
        .finally(() => {
          state.loading = false;
          if (state.logincount >= 5) {
            state.showcaptcha = true;
          }
        });
    }

    return {
      state,
      emailValidator,
      passwordValidator,
      openUrl,
      login,
      data,
      v$,
      recaptcha,
      recaptchaError,
      recaptchaVerify,
      ssoVisibleClick
    };
  }
};
</script>
<style lang="scss">
  #app .sso-visible .link-container .icon.left{
    margin-right: 19px !important;
  }
</style>
<style lang="scss" scoped>
@import "/submodules/vue-framework/src/scss/Colors.scss";
@import "/submodules/vue-framework/src/scss/FontFaces.scss";

.form-controls {
    display: flex;
    flex-direction: row;
    margin-top:40px;
    justify-content: space-between;
}

.links {

  align-items: flex-end;

}

.sso-visible {
  margin-left: 10px;
}

.text-separator {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    margin: 35px auto;
    justify-content: center;
    font-weight: 900;
    color: $text;
    font-family: "Greycliff CF", sans-serif;
    font-size: 14px;

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }

    &::before, &::after {
        display: block;
        height: 2px;
        opacity: 0.5;
        background-color: $border-1;
        margin: 7.5px 0;
        content: "";
        position: absolute;
        top: 0;
        width: 45%;
    }
}

.oauth-providers {
    display: flex;
    flex-direction: column;
    max-width: 100%;

    .button-container {
        width: 100%;
        margin-bottom: 10px;

        &::v-deep(.oauth-button) {
            width: 100%;
            justify-content: flex-start;
            background-color: white !important;
        }

        &::v-deep(.oauth-button:hover) {
            color:#415d6e !important;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

}

</style>

<template>
  <div>
    <div v-if="!data.state.whitelabel" class="maglr-logo" />
    <div class="text">
      <h1>{{ $t('login_single_sign_on_title') }}</h1>
      <p>{{ $t('login_single_sign_on_description') }}</p>
    </div>
    <div v-if="data.state.providers.length > 0" class="thirdparty-login">
      <div class="oauth-providers">
        <MButton
          v-for="oauthProvider in data.state.providers"
          :key="oauthProvider.name"
          :icon-pack="oauthProvider.iconPack"
          :icon-left="oauthProvider.icon"
          class="oauth-button"
          variant="is-outlined is-light"
          size="is-large"
          @click="() => openUrl(oauthProvider.destination)"
        >
          {{ $t('login met') }} {{ oauthProvider.name }}
        </MButton>
      </div>
    </div>
  </div>
</template>

<script>
import { useData } from '../../services/Data';
import { reactive } from 'vue';
import MButton from '../../../../submodules/vue-framework/src/components/Element/Button.vue';

export default {
  name: 'LoginSingleSignOn',
  components: { MButton },
  setup () {
    const data = useData();
    const state = reactive({
      email: '',
      password: '',
      errors: { email: '', password: '' },
      loading: false
    });
    function openUrl (url) {
      window.location.href = url;
    }
    return {
      state,
      data,
      openUrl
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../submodules/vue-framework/src/scss/Colors.scss";
@import "../../../../submodules/vue-framework/src/scss/FontFaces.scss";

.text{
  padding-bottom: 36px;
}
.hidden {
  display: none;
}

h1 {
  font-family: "Greycliff CF", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.49999991059303284px;
  text-align: left;
}

p {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
}
.maglr-logo {
  background-repeat: no-repeat;
  background-size: contain;
}
.error{
  padding: 10px;
  margin-bottom: 5px;
  display: flex;
  width: 100%;
  background-color: #f1dada;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  color: #950202;
  border: 1px solid;
}

.maglr-logo {
  width: 96px;
  height: 39px;
  margin-bottom: 30px;
  background-image: url("../../../img/logo_2019_green.svg");
}

.text-separator {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  margin: 25px auto;
  justify-content: center;
  font-weight: 900;
  color: $text;
  font-family: "Greycliff CF", sans-serif;
  font-size: 14px;

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  &::before, &::after {
    display: block;
    height: 2px;
    opacity: 0.5;
    background-color: $border-1;
    margin: 7.5px 0;
    content: "";
    position: absolute;
    top: 0;
    width: 45%;
  }
}

.button-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .provider-icon {
    margin-left: 10px;
    margin-right: 15px;
  }

  .provider-name {
    margin-right: auto;
  }
}

.oauth-providers {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  .button-container {
    width: 100%;
    margin-bottom: 10px;

    &::v-deep(.oauth-button) {
      width: 100%;
      justify-content: flex-start;
      background-color: transparent;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

</style>

<template>
  <div>
    <h3 class="loginheader">
      {{ $t('request new password title') }}
    </h3>
    <div v-if="state.step === 1" class="intro">
      {{ $t('request new password description') }}
    </div>
    <form v-if="state.step === 1" @submit.prevent>
      <input type="hidden" name="_token" :value="csrf">
      <MField
        label-variant="is-dark"
        :validator="emailValidator"
        :label-left="$t('e-mailadres')"
        label-alignment="is-top"
        label-position="is-top-bottom"
      >
        <MInput
          v-model="state.email"
          name="email"
          :placeholder="$t('e-mailadres')"
          @keyup="checkError()"
          @keyup.enter="submit()"
        />
      </MField>
    </form>
    <div v-else>
      {{ $t('request for new password is send') }}
    </div>
  </div>

  <div class="form-controls">
    <div class="links">
      <MLink is="router-link" v-if="state.step === 1 && !data.state.whitelabel" target="_self" to="/login" variant="is-darker" icon-left="chevron-left">
        {{ $t('back') }}
      </MLink>
      <MButton v-else-if="!data.state.whitelabel" variant="is-light" icon-left="chevron-left" @click="back">
        {{ $t('back') }}
      </MButton>
    </div>

    <MButton v-if="state.step === 1" :loading="state.loading" size="is-large" variant="is-accent-2" @click="submit">
      {{ $t('request new password button') }}
    </MButton>
  </div>
</template>

<script>
import { computed, reactive } from 'vue';
import { useApi } from '../../services/Api';
import { useI18n } from 'vue-i18n';
import { useData } from '../../services/Data';
import { useRouter } from 'vue-router';
import MField from '../../../../submodules/vue-framework/src/components/Container/Field.vue';
import MInput from '../../../../submodules/vue-framework/src/components/Form/Input.vue';
import MLink from '../../../../submodules/vue-framework/src/components/Element/Link.vue';
import MButton from '../../../../submodules/vue-framework/src/components/Element/Button.vue';

export default {
  name: 'RequestPassword',
  components: { MButton, MLink, MInput, MField },
  setup () {
    const Api = useApi();
    const i18n = useI18n();
    const data = useData();
    const router = useRouter();
    const state = reactive({
      email: '',
      errors: { email: '' },
      loading: false,
      step: 1
    });
    function checkError () {
      if (typeof state.errors.email !== 'undefined' && state.errors.email !== '') {
        const regex = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,}$/;
        if (typeof state.email === 'string' && regex.test(state.email)) {
          state.errors.email = '';
        }
      }
    }

    async function submit () {
      state.errors = {};
      const regex = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,}$/;
      if (typeof state.email === 'string' && !regex.test(state.email)) {
        state.errors.email = i18n.t('error invalid email');
        return;
      }

      await Api.requestLostpassword({ email: state.email }).then(
        r => {
          if (typeof r.data.lang !== 'undefined') {
            window.i18n.global.locale = r.data.lang;
          }
          state.step = 2;
        }
      ).catch(error => {
        if (typeof error.response?.data.lang !== 'undefined') {
          window.i18n.global.locale = error.response?.data.lang;
        }
        state.errors.email = i18n.t('error invalid user');
        if (error.response?.data?.errors?.email !== '' && error.response?.data?.errors?.email[0] !== '') {
          state.errors.email = (error.response?.data?.errors?.email[0]).replace('[[provider]]', error.response?.data?.errors?.sso_provider[0]);
        }
        if (error.response?.data?.message != null &&
            error.response.data?.message !== 'The given data was invalid.') {
          state.errors.email = error.response.data.message;
        }
      })
        .finally(() => {
          state.loading = false;
        });
    }

    const emailValidator = computed(() => {
      if (state.errors.email) {
        return {
          message: state.errors.email,
          state: 'is-danger',
          validation: false
        };
      }

      return {
        message: '',
        state: 'is-default',
        validation: true
      };
    });

    function back () {
      router.push('/login');
    }

    return {
      data,
      state,
      emailValidator,
      submit,
      back,
      checkError
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../submodules/vue-framework/src/scss/Colors.scss";
@import "../../../../submodules/vue-framework/src/scss/FontFaces.scss";

.intro{
  padding-bottom: 10px;
}

.links {margin-left:0px;
  margin-top: 10px;}

.form-controls {
  display: flex;
  justify-content: space-between;
  margin-top:15px;

}

</style>

<template>
  <span
    class="button-container"
    :class="[
      variant,
      size,
      {
        'is-loading': loading,
        'is-transparent': transparent,
        'is-disabled': disabled,
        'is-rounded': rounded
      }
    ]"
  >
    <component
      :is="tag"
      class="button"
      :disabled="disabled"
      :type="type"
      :class="{
        'is-active': active,
        'is-hovered': hovered
      }"
      :value="label ? label : defaultSlot?.[0].text"
      v-bind="$attrs"
      @click="$emit('click', $event)"
    >
      <MIcon v-if="iconLeft" :pack="iconPack" class="left" :icon="iconLeft" />
      <span v-if="label">
        {{ label }}
      </span>
      <span v-else-if="defaultSlot">
        <slot />
      </span>
      <MIcon v-if="iconRight" :pack="iconPack" class="right" :icon="iconRight" />
    </component>
  </span>
</template>

<script>
import MIcon from './Icon.vue';
export default {
  name: 'MButton',
  components: { MIcon },
  status: 'ready',
  release: '1.0.0',
  inheritAttrs: false,
  props: {
    /**
     * HTML5 type kind, `button` or `submit` or `reset`
     */
    type: {
      type: String,
      default: 'button',
      validator: (v) => {
        return ['button', 'submit', 'reset'].indexOf(v) !== -1;
      }
    },
    /**
     * @deprecated Use variant='is-rounded' instead. Rounded is not a component state so no dedicated prop is required.<br>
     * Helper prop/short hand for `class='is-rounded'`
     */
    rounded: Boolean,
    /**
     * Variant of the button, `is-primary`, `is-secondary`, `is-accent-1`, `is-accent-2`, `is-accent-3` (same as `is-secondary`), `is-accent-3`, `is-accent-4`, `is-transparent`
     * Each variant has also modifiers like `is-loading`, `is-hovered`, `is-active`, `is-disabled`, `is-rounded`
     */
    variant: {
      type: String,
      default: 'is-primary'
    },
    /**
     * Size of the button, `is-small` or `is-medium` or `is-large`
     */
    size: {
      type: String,
      default: 'is-medium',
      validator: (v) => {
        return ['is-small', 'is-medium', 'is-large'].indexOf(v) !== -1;
      }
    },
    /**
     * Helper prop/short hand for `class='is-loading'`
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * Helper prop/short hand for `class='is-active'`
     */
    active: {
      type: Boolean,
      default: false
    },
    /**
     * Helper prop/short hand for `class='is-hovered'`
     */
    hovered: {
      type: Boolean,
      default: false
    },
    /**
     * Helper prop/short hand for `class='is-transparent'`
     */
    transparent: {
      type: Boolean,
      default: false
    },
    /**
     * HTML5 tag kind. `button` or `a` or `input`(only use in combination with the type prop)
     */
    tag: {
      type: String,
      default: 'button',
      validator: (v) => {
        return ['button', 'a', 'input'].indexOf(v) !== -1;
      }
    },
    /**
     * Text content for the button
     */
    label: {
      type: [String, Boolean],
      default: false
    },
    /**
     * Icon for the left side of the button
     */
    iconLeft: {
      type: [String, Boolean],
      default: false
    },
    /**
     * Icon for the right side of the button
     */
    iconRight: {
      type: [String, Boolean],
      default: false
    },
    /**
     * Icon pack for the icon used inside the button
     */
    iconPack: {
      type: String,
      default: 'fas'
    },
    /**
     * Button disabled state, true if disabled, false if enabled.
     */
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  computed: {
    defaultSlot () { // vue2+vue3 compatibility
      if (typeof this.$slots.default === 'function') {
        return this.$slots.default();
      } else {
        return this.$slots.default;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@use "sass:color";
@import '/submodules/vue-framework/src/scss/Functions.scss';
@import '/submodules/vue-framework/src/scss/Colors.scss';

//* BUTTON BASE *//
.button-container {
  .button {
    display: inline-flex;
    width: auto;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    border-radius: 2px;
    user-select: none;
    border-top: none;
    border-left: none;
    cursor: pointer;
    outline: none;
    position: relative;
    transition: ease all 100ms;
    background-color: transparent;
  }
}
//* END BUTTON DEFAULT *//

//* BUTTON ELEMENT MODIFIERS *//
a.button {
  span {
    line-height: 1.2;
  }
}
button.button {
}
input.button {
}
//* END BUTTON ELEMENT MODIFIERS *//

//* BUTTON STATES *//
.button-container {
  &.is-disabled,
  &[disabled] {
    cursor: not-allowed;
    .button {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  &.is-loading {
    .button {
      color: transparent !important;
      cursor: wait;
      pointer-events: none;
      &::after {
        left: calc(50% - (1em / 2));
        top: calc(50% - (1em / 2));
        position: absolute;
        animation: spinAround 0.5s infinite linear;
        border-radius: 290486px;
        content: '';
        display: block;
        height: 1em;
        width: 1em;
      }
    }
  }
}
//* END BUTTON STATES *//

//* BUTTON SIZES *//
.button-container {
  &.is-small {
    .button {
      min-height: 28px;
      height: 28px;
      font-size: 14px;
      padding: 0 9px;
    }
    .icon {
      font-size: 100%;
    }
  }
  &.is-medium {
    .button {
      min-height: 35px;
      height: 35px;
      font-size: 16px;
      padding: 0 13px;
    }
    .icon {
      font-size: 100%;
    }
  }
  &.is-large {
    .button {
      min-height: 42px;
      height: 42px;
      font-size: 18px;
      padding: 0 16px;
    }
    .icon {
      font-size: 100%;
    }
  }
}
//* END BUTTON SIZES *//

// THE ACTUAL BUTTON CLASS GENERATOR
@each $name, $color in $colors {
  .button-container.#{$name} {
    .button {
      color: #fff;
      background-color: $color;
      border-bottom: 2px solid color.adjust($color, $lightness: -6%);
      border-right: 2px solid color.adjust($color, $lightness: -6%);
      &:hover,
      &.is-hovered {
        color: #fff;
        background-color: color.adjust($color, $lightness: -10%);
      }

      &:active,
      &.is-active {
        color: #fff;
        background-color: color.adjust($color, $lightness: -15%);
      }
    }
    &.is-loading {
      .button {
        &::after {
          border: 2px solid light-or-dark($color);
          border-right-color: transparent;
          border-top-color: transparent;
        }
      }
    }
  }
  .button-container.hover\:#{$name} {
    .button {
      &:hover,
      &.is-hovered {
        color: $color !important;
        background-color: transparent !important;
        .icon {
          color: $color !important;
          &:first-child {
            border: 1px solid $color;
          }
        }
      }
    }
  }
  .button-container.active\:#{$name} {
    .button {
      &:active,
      &.is-active {
        color: $color !important;
        background-color: transparent !important;
        .icon {
          color: $color !important;
          &:first-child {
            border: 1px solid $color;
          }
        }
      }
    }
  }
  .button-container.is-inversed.hover\:#{$name} {
    .button {
      &:hover,
      &.is-hovered {
        color: $color !important;
        background-color: transparent !important;
        .icon {
          color: $color !important;
          &:first-child {
            border: 1px solid $color;
          }
        }
      }
    }
  }
  .button-container.is-inversed.active\:#{$name} {
    .button {
      &:active,
      &.is-active {
        color: $color !important;
        background-color: transparent !important;
        .icon {
          color: $color !important;
          &:first-child {
            border: 1px solid $color;
          }
        }
      }
    }
  }
  .button-container.is-inversed.#{$name} {
    .button {
      color: $color;
      background-color: transparent;
      border-bottom: none;
      border-right: none;

      &:hover,
      &.is-hovered {
        color: color.adjust($color, $lightness: -10%);
      }

      &:active,
      &.is-active {
        color: color.adjust($color, $lightness: -15%);
      }
    }
    &.is-loading {
      .button {
        &::after {
          border: 2px solid light-or-dark($color);
          border-right-color: transparent;
          border-top-color: transparent;
        }
      }
    }
  }
}
// END

//* BUTTON VARIATIONS *//
.button-container {
  &.is-outlined {
    .button {
      font-weight: 400;
      transition: ease all 100ms;
    }

    &.is-dark {
      .button {
        color: #fff;
        background-color: $darker;
        border: 1px solid $darker;

        &:hover,
        &.is-hovered {
          color: $darker;
          background-color: $lighter;
          border: 1px solid $lighter;
        }

        &:active,
        &.is-active {
          color: $darker;
          background-color: transparent;
          border: 1px solid $lighter;
        }
      }
    }

    &.is-light {
      .button {
        color: $darker;
        background-color: $lighter;
        border: 1px solid $lighter;

        &:hover,
        &.is-hovered {
          color: #fff;
          background-color: $darker;
          border: 1px solid $darker;
        }

        &:active,
        &.is-active {
          color: #fff;
          background-color: $darker;
          border: 1px solid $darker;
        }
      }
    }

    &.is-primary {
      .button {
        color: $light;
        border: 1px solid $light;

        &:hover,
        &.is-hovered {
          background-color: transparent;
          border: 1px solid $primary;
          color: $primary;
          transform: scale(1.2);
        }

        &:active,
        &.is-active {
          color: $primary;
          background-color: transparent;
          border: 1px solid $primary;
        }
      }
    }

    &.is-loading {
      &::after {
        border: 2px solid $darker;
        border-right-color: transparent;
        border-top-color: transparent;
        left: calc(47% - (1em / 2));
        top: calc(45% - (1em / 2));
      }
    }
  }
  &.is-circle-1 {
    .button {
      color: $dark;
      font-weight: 400;
      border: none;
      padding: 0;

      .icon.left {
        margin: 0;
        border: 1px solid $secondary;
        width: 32px;
        height: 32px;
        border-radius: 20px;
        background-color: transparent;
        color: $secondary;
        transition: ease all 100ms;
      }

      &:hover,
      &.is-hovered {
        color: $primary;
        .icon.left {
          color: $primary;
          border-color: $primary;
        }
      }
    }
  }
}
//* END BUTTON VARIATIONS *//

//* BUTTON VARIATION MODIFIERS *//
.button-container {
  &.is-rounded {
    .button {
      border-radius: 20px;
    }
  }
}
//* END BUTTON VARIATION MODIFIERS *//

//* BUTTON WITH ICON *//
.button .icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  min-height: 16px;

  &:first-child:not(:last-child) {
    //margin-left: calc(-.175em - 1px);
    //margin-right: .3875em;
  }

  &:last-child:not(:first-child) {
    margin-left: 0.3875em;
    margin-right: calc(-0.175em - 1px);
  }

  &:first-child:last-child {
    margin-left: calc(-0.175em - 1px);
    margin-right: calc(-0.175em - 1px);
  }
}
//* END BUTTON WITH ICON *//
</style>

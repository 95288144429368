<template>
  <div>
    <div v-if="data.state.loadingUser">
      Loading information
    </div>
    <div v-else>
      <!-- Create new password -->
      <div v-if="state.status === 'reset'">
        <div class="maglr-login">
          <div class="intro">
            <h3 class="loginheader">
              {{ $t('reset new password title') }}
            </h3>
            <p>{{ $t('reset new password description') }}</p>
          </div>
          <form @submit.prevent>
            <MField
              label-variant="is-dark"
              :validator="passwordValidator"
              :label-left="$t('new_password')"
              label-alignment="is-top"
              label-position="is-top-bottom"
            >
              <MInput
                v-model="state.password"
                autocomplete="new-password"
                name="password"
                type="password"
                :placeholder="$t('new_password')"
                @keyup.enter="confirm()"
                @keyup="checkError()"
              />
            </MField>
          </form>
          <div class="form-controls">
            <div class="links">
              <MLink to="javascript::void(0)" variant="is-darker" target="_self" icon-left="long-arrow-left" @click="toLogin">
                {{ $t('back') }}
              </MLink>
            </div>
            <MButton class="right" :loading="state.loading" size="is-large" variant="is-accent-2" @click="confirm">
              {{ $t('reset new password button') }}
            </MButton>
          </div>
        </div>
      </div>
      <div v-if="state.status === 'success'">
        <div class="maglr-login">
          <div class="passord-saved">
            <h3 class="loginheader">
              {{ $t('reset new password title success') }}
            </h3>
            <p>{{ $t('reset new password description success') }}</p>
          </div>
          <div class="form-controls">
            <MButton is="router-link" class="right" :loading="state.loading" size="is-large" variant="is-accent-2" @click="toLogin">
              {{ $t('login') }}
            </MButton>
          </div>
        </div>
      </div>
      <div v-if="state.status === 'error'">
        <div class="maglr-login">
          <div class="passord-saved">
            <h3 class="loginheader">
              {{ $t('reset new password title error') }}
            </h3>
            <p class="error">
              {{ state.errormessage }}
            </p>
          </div>
          <div class="form-controls">
            <div class="links">
              <MLink to="javascript::void(0)" variant="is-darker" target="_self" icon-left="long-arrow-left" @click="toLogin">
                {{ $t('back') }}
              </MLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue';
import { useData } from '../../services/Data';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useApi } from '../../services/Api';
import MField from '../../../../submodules/vue-framework/src/components/Container/Field.vue';
import MInput from '../../../../submodules/vue-framework/src/components/Form/Input.vue';
import MLink from '../../../../submodules/vue-framework/src/components/Element/Link.vue';
import MButton from '../../../../submodules/vue-framework/src/components/Element/Button.vue';

export default {
  name: 'ResetPassword',
  components: { MButton, MLink, MInput, MField },
  setup () {
    const data = useData();
    const router = useRouter();
    const route = useRoute();
    const api = useApi();
    const i18n = useI18n();
    const loginForm = ref(null);
    const state = reactive({
      email: '',
      password: '',
      errors: {},
      loading: false,
      status: 'reset',
      errormessage: ''
    });
    watch(() => data.state.loadingUser, (first, second) => {
      if (first === false) {
        // user loaded
        if (data.state.needSingleSignOn === true) {
          router.push('/login/sso/activate');
        }
      }
    }, { immediate: true });

    if (typeof route.query.h !== 'undefined' && typeof route.query.u !== 'undefined' && route.query.h !== '' && route.query.u !== '') {
      api.getUserByPasswordToken({ token: route.query.h, verifyuser: route.query.u }).then((r) => {
        data.state.email = r.data.user.email;
        data.state.user = r.data.user;
        data.state.singleSignOnProvider = r.data.single_sign_ex_provider;
        data.state.hasSingleSignOn = r.data.has_single_sign_on;
        data.state.needSingleSignOn = r.data.single_sign_on;
        if (typeof r.data.lang !== 'undefined') {
          window.i18n.global.locale = r.data.lang;
        }
        if (data.state.needSingleSignOn === true) {
          router.push('/login/sso/activate');
        }
      }).catch(error => {
        if (typeof error.response.data.exception !== 'undefined') {
          state.status = 'error';
          state.errormessage = error.response.data.exception;
        }
        if (typeof error.response.data.message !== 'undefined') {
          state.status = 'error';
          state.errormessage = error.response.data.message;
        }
      }).finally(() => { state.loadingUser = false; });
    } else {
      state.status = 'error';
      state.errormessage = i18n.t('reset new password title error');
    }

    function toLogin () {
      router.push('/login');
    };

    function checkError () {
      if (typeof state.errors.password !== 'undefined' && state.errors.password !== '') {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        if (typeof state.password === 'string' && regex.test(state.password)) {
          state.errors.password = '';
        }
      }
    }

    function confirm () {
      state.errors = {};
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
      if (typeof state.password === 'string' && !regex.test(state.password)) {
        state.errors.password = i18n.t('password_requirements');
        return;
      }
      state.loading = true;
      api.setPassword({ password: state.password, token: route.query.h, verifyuser: route.query.u }).then((r) => {
        state.status = 'success';
      }).catch(e => {
        if (typeof e.response.data.exception !== 'undefined') {
          state.status = 'error';
          state.errors.password = e.response.data.exception;
        }
        if (typeof e.response.data.message !== 'undefined') {
          state.errors.password = e.response.data.message;
        }
      }).finally(() => {
        state.loading = false;
      });
    }

    const passwordValidator = computed(() => {
      if (state.errors.password) {
        return {
          message: state.errors.password,
          state: 'is-danger',
          validation: false
        };
      }

      return {
        message: '',
        state: 'is-default',
        validation: true
      };
    });

    return {
      state,
      passwordValidator,
      toLogin,
      loginForm,
      checkError,
      confirm,
      data
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../submodules/vue-framework/src/scss/Colors.scss";
@import "../../../../submodules/vue-framework/src/scss/FontFaces.scss";

.maglr-logo {
    background-repeat: no-repeat;
    background-size: contain;
}

.login-form {
    font-size: 16px;

    .field-container {
        margin: 3rem 0;
    }
}

.login-form {
    &::v-deep(.input-control) {
        width: 100%;
    }
}

.passord-saved {
  padding-bottom: 20px;
}

.form-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.links {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-left: 0;
}

.maglr-logo {
    width: 96px;
    height: 39px;
    margin-bottom: 30px;
    background-image: url("../../../img/logo_2019_green.svg");
}

h1 {
    font-size: 25px;
    color: #415d6e;
    margin-bottom: 16px;
    /*letter-spacing: 2px;*/
}
h2 {
    font-size: 16px;
    color: #415d6e;
    /*letter-spacing: 2px;*/
}
.text {
  font-size: 16px;
  font-family: Roboto, sans-serif;
  color: #6A6A6A;
}
.login-text {
    font-size: 35px;
    font-weight: bold;
    color: $primary;
    text-shadow: 0 1px, 1px 0, 1px 1px $primary;
    /*letter-spacing: 2px;*/
}

.text-separator {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    margin: 25px auto;
    justify-content: center;
    font-weight: 900;
    color: $text;
    font-family: "Greycliff CF", sans-serif;
    font-size: 14px;

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }

    &::before, &::after {
        display: block;
        height: 2px;
        opacity: 0.5;
        background-color: $border-1;
        margin: 7.5px 0;
        content: "";
        position: absolute;
        top: 0;
        width: 45%;
    }
}

.button-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .provider-icon {
        margin-left: 10px;
        margin-right: 15px;
    }

    .provider-name {
        margin-right: auto;
    }
}

.oauth-providers {
    display: flex;
    flex-direction: column;
    max-width: 100%;

    .button-container {
        width: 100%;
        margin-bottom: 10px;

        &::v-deep(.oauth-button) {
            width: 100%;
            justify-content: flex-start;
            background-color: transparent;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

</style>
